import React, { useState, useMemo } from 'react';
import ReactGA from 'react-ga';
import Table from './component/table';
import { createTableData, dataColumns } from './util/util';
import Policies from './policies.json';
import './App.css';

function App () {
  const { companies } = Policies;
  const columns = useMemo(() => dataColumns, []);
  const data = useMemo(() => createTableData(companies), [companies]);
  const [searchValue, setSearchValue] = useState('');

  const onValueChange = (e) => {
    const value = e.target.value;
    setSearchValue(value || undefined);
  }

  ReactGA.initialize('UA-208043-46');
  ReactGA.pageview('/');

  function renderStatText() {
    let totalWorkers = 0;
    let totalLimbo = 0;
    let totalForever = 0;
    let totalFixed = 0;

    let fixedCorp = 0;
    let foreverCorp = 0;
    let limboCorp = 0;

    let numberWithCommas = function(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    for (const [companyId, companyValue] of Object.entries(Policies.companies)) {
      let workforce = 0;
      if (companyValue.workforce !== undefined) {
        workforce = companyValue.workforce;
        totalWorkers += workforce;
        if (companyValue.policies !== undefined) {
            let recentPolicy = companyValue.policies[0]; // might need to be update to reflect recent date
            let dateUntil = null;
            if (recentPolicy.closedUntil !== undefined) {
                dateUntil = recentPolicy.closedUntil;
            }
            if (dateUntil !== null) {
                if (dateUntil === 'undecided') {
                    totalLimbo += workforce;
                    limboCorp++;
                } else if (dateUntil === 'forever') {
                    totalForever += workforce;
                    foreverCorp++;
                } else {
                    totalFixed += workforce
                    fixedCorp++;
                }
            }
        }
      }
    }

    return <span>
            Out of <b>{numberWithCommas(totalWorkers)}</b> workers who are currently working from home,&nbsp;
            <b>{numberWithCommas(totalFixed)}</b> workers are given certain guaranteed months to work from home ({fixedCorp} companies),&nbsp;
            <b>{numberWithCommas(totalForever)}</b> workers have been allowed to work from home forever ({foreverCorp} companies),
            and <b>{numberWithCommas(totalLimbo)}</b> workers are left in indecisive limbo ({limboCorp} companies).
        </span>
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-header-group">
          <h1>work from home tracker</h1>
          <div className="form-group">
            <form className="search">
              <input type="search" placeholder="Search" 
                onChange={onValueChange}>  
              </input>
            </form>
            <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSf8Tr6qCY4j_huWSclUp19Nq3ZEhHkTzm_zJMST_8z110kcKQ/viewform?usp=sf_link" className="submit-button">
              Send Update
            </a>
          </div>
        </div>
      </header>
      <div className="statText">
        {renderStatText()}
      </div>
      <Table
        columns={columns}
        data={data}
        searchValue={searchValue}
      />
      <div className="producthunt">
         <a rel="noopener noreferrer" href="https://www.producthunt.com/posts/work-from-home-tracker?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-work-from-home-tracker" target="_blank">
            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=253516&theme=light" alt="Work From Home Tracker - Daily Updated Database of Company Work From Home Policies | Product Hunt Embed" className="producthunt-badge" width="250" height="54" />
        </a>
      </div>
      <div className="footer">
        2020 &copy; <a rel="noopener noreferrer" href="https://www.squidcode.com" target="_blank">Squidcode LLC</a><br/>
        Made in California
      </div>
    </div>
  );
  
}

export default App;
