import React, { useEffect } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import './table.css';

export default function Table ({columns, data, searchValue}) {

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      filterTypes,
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
            const rowOneColumn = row1.values[columnName];
            const rowTwoColumn = row2.values[columnName];
            if ((rowOneColumn === undefined)) {
                return -1;
            }
            if ((rowTwoColumn === undefined)) {
                 return 1;
             }
             if (isNaN(rowOneColumn) || isNaN(rowTwoColumn)) {
                let c1 = rowOneColumn;
                let c2 = rowTwoColumn;
                if (!(isNaN(rowOneColumn))) {
                    c1 = '';
                }
                if (!(isNaN(rowTwoColumn))) {
                    c2 = '';
                }
                let r1 = c1.toUpperCase();
                let r2 = c2.toUpperCase();
                return r1 > r2 ? 1 : -1;
            }
            return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
        }
      },
      data,
      initialState: {
        sortBy: [
          {
            id: 'companyTitle',
            desc: false
          }
        ]
      },
      disableSortRemove: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  )
  
  useEffect(() => {
    setGlobalFilter(searchValue)
  }, [searchValue])

  const count = rows.length;

  return (
    <div className='data-table'>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps({
                    className: column.headerClassName
                  }))}>
                    <span className="headerStyle">
                      {column.render('Header')}
                    </span>
                    <span className="sortIndicator">
                      {column.isSorted
                        ? column.isSortedDesc
                          ? '▼'
                          : '▲'
                        : ' '}
                    </span>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps({
                        className: cell.column.className
                      })}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>
      <div className='no-results'>{count ? (count > 1 ? count + " companies" : "1 company") : "No results found!"}</div>
      <br />
    </div>
  )
}


