import React from 'react';

var extractHostName = function(url) {
    var hostname;
    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }
    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];
    return hostname;
}

var numberWithCommas = function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

var monthDiff = function(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

var calculateMonthsLeft = function(closedUntil) {
    if (closedUntil === 'undecided') {
        return '?';
    }
    if (closedUntil === 'forever') {
        return '∞';
    }
    let past = new Date(closedUntil);
    let now = new Date();
    return monthDiff(now, past) + 1;
}

var getUniqueId = function() {
  let min = 10000000;
  let max = 99999999;
  return Math.floor(Math.random() * (max - min) ) + min;
}

export function createTableData(dataObj) {
  const dataArray = [];
  for (const company in dataObj) {
    const { displayName, headquarters, policies, workforce } = dataObj[company];
    const { sources, closedUntil, dateAdded, policy, verifiedEmail } = policies[0];

    let monthsLeft = calculateMonthsLeft(closedUntil);

    let tableData = {
      searchTerm: company,
      companyTitle: displayName,
      headquarters,
      workforce,
      closedUntil,
      monthsLeft,
      policy,
      dateAdded,
      sources: {
        sourceUrl: sources,
        verifiedEmail
      },
    };
    dataArray.push(tableData);
  }
  return dataArray;
}

export const dataColumns = [
  {
    Header: "Company",
    accessor: "companyTitle",
    className: "company-title",
    Cell: function(props) {
      const {value} = props;
      const isLong = value.length > 15;
      const isLongClass = isLong ? 'long' : '';
      return (
        <div className={`title ${isLongClass}`}>
          <b>{props.value}</b>
        </div>
      )
    }
  },
  {
    Header: "Headquarters",
    accessor: "headquarters",
    disableFilters: true,
    className: "fourth-togo",
    headerClassName: "fourth-togo",
  },
  {
    Header: "Workforce",
    accessor: "workforce",
    disableFilters: true,
    className: "tdRight second-togo",
    headerClassName: "second-togo",
    Cell: function(props) {
        const value = props.value;
        if (value !== undefined) {
            return numberWithCommas(value);
        }
        return 'n/a';
    }
  },
  {
    Header: "Work From Home Until",
    accessor: "closedUntil",
    disableFilters: true,
    headerClassName: "shorten-name",
    Cell: function(props) {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const value = props.value;
        if (value === 'forever') {
            return value;
        }
        if (value === 'undecided') {
            return value;
        }
        if (value !== undefined) {
            const originalDateObj = new Date(value);
            const dateObj = new Date(originalDateObj.setMonth(originalDateObj.getMonth()+1));
            return dateObj.getFullYear() + ' ' + monthNames[dateObj.getMonth() ];
        }
        return 'n/a';
    }
  },
  {
    Header: "Months Left",
    accessor: "monthsLeft",
    disableFilters: true,
    className: "tdRight",
  },
  {
    Header: "Last Update",
    accessor: "dateAdded",
    disableFilters: true,
    className: "first-togo",
    headerClassName: "first-togo",
  },
  {
    Header: "Sources",
    accessor: "sources",
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
    className: "third-togo",
    headerClassName: "third-togo",
    Cell: function(props) {
        const sourceValue = props.value;
        if (sourceValue.sourceUrl !== undefined) {
            let value = sourceValue.sourceUrl;
            if (value !== undefined) {
                var sourceComponents = value.map(function(source) {
                    const domain = extractHostName(source).replace("www.", "");
                    return <a key={getUniqueId()} target="_blank" rel="noopener noreferrer" className="sources" href={source}> { domain }</a>
                });
                return <div>{sourceComponents}</div>
            }
        }
        let verifiedEmail = sourceValue.verifiedEmail;
        console.log(verifiedEmail);
        if (verifiedEmail !== undefined) {
            if (verifiedEmail === true) {
                return <div><span className="verified-source">verified source</span></div>
            }
        }
        return <span></span>;
    }
  },
]